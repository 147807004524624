import { RichText } from "prismic-reactjs";
import React from "react";
import "./style.scss";

export default function Hero({ data }) {
  return (
    <section className="about-hero">
      <div className="container">
        <div className="about-hero-content">
          <RichText render={data.title} />
          <RichText render={data.description} />
        </div>
        <div className="about-hero-image">
          <img src={data.image.url} alt="tech" />
        </div>
      </div>
    </section>
  );
}
