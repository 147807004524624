import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function Additional({ data }) {
  return (
    <section className="about-additional">
      <div className="container">
        <div className="about-additional-image">
          <img src={data.primary.image1.url} alt="" />
        </div>
        <div className="about-additional-content">
          <RichText render={data.primary.title1} />
          <RichText render={data.primary.description1} />
        </div>
      </div>
    </section>
  );
}
