import React from "react";
import Member from "./member";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function Team({ data }) {
  return (
    <section className="about-team">
      <div className="container">
        <RichText render={data.primary.title1} />
        <RichText render={data.primary.subtitle} />
        <div className="about-team-members">
          {data.items.map((list, index) => {
            return (
              <Member
                key={index}
                src={list.image1.url ? <img src={list.image1.url} alt="" /> : <div className="dummy-img"></div>}
                name={<RichText render={list.name} />}
                designation={<RichText render={list.designation} />}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
