import React from "react";

import Layout from "../../components/layout";
import Hero from "../../components/company/about/hero";
import Additional from "../../components/company/about/additional";
import Team from "../../components/company/about/team";
import SecondaryFooter from "../../components/layout/secondaryFooter";
import { graphql } from "gatsby";

export default function AboutUs(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicAboutUs.edges[0].node.dataRaw;
  return (
    <Layout>
      <Hero data={data} />
      <Additional data={data.body[0]} />
      <Team data={data.body[1]} />
      <SecondaryFooter />
    </Layout>
  );
}

export const about = graphql`
  query about {
    allPrismicAboutUs {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
